import { plutoClient } from "../services/http"
import { PlutoAPIRoutes } from "../constants/pluto"

export const getUser = () =>
  window.localStorage.getItem("plutoUser")
    ? JSON.parse(window.localStorage.getItem("plutoUser"))
    : {}

const setUser = user =>
  window.localStorage.setItem("plutoUser", JSON.stringify(user))

const setSite = site =>
  window.localStorage.setItem("plutoSite", JSON.stringify(site))

const getSite = () => {
  const site = window.localStorage.getItem("plutoSite")
  return JSON.parse(site)
}

export const handleLogin = async (values, site) => {
  return await plutoClient(
    PlutoAPIRoutes.LOGIN,
    'POST',
    {
      email: values.email,
      password: values.password
    },
    {authHeader: false, headers: {'X-Site-Id': site.id}},
  ).then(
    async (response) => {
      setUser(response)
      setSite(site)

      return {
        success: true,
      }
    },
    async (errors) => {
      return {
        success: false,
        content: errors
      }
    },
  );
}

export const resetPassword = async (values, site) => {
  return await plutoClient(
    PlutoAPIRoutes.RESET_PASSWORD,
    'POST',
    {
      email: values.email
    },
    {authHeader: false, headers: {'X-Site-Id': site.id}},
  ).then(
    async (response) => {
      return {
        success: true,
      }
    },
    async (errors) => {
      return {
        success: false,
        content: errors
      }
    },
  );
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.access_token
}

export const logout = async (expired = false) => {
  const site = getSite();

  let url = '/login';

  if (expired) {
    url += '/' + site.id + '?session_expired=true';
  }

  return await plutoClient(
    PlutoAPIRoutes.LOGOUT,
    'GET',
    {},
    {authHeader: true, headers: {}},
  ).then(
    async (response) => {
      setUser({})
      setSite({})

      window.location.replace(url);
    },
    async (errors) => {
      setUser({})
      setSite({})

      window.location.replace(url);
    },
  );
}

export const handleSignup = async (values, siteId) => {
  return await plutoClient(
    PlutoAPIRoutes.SIGNUP,
    'POST',
    values,
    {authHeader: false, headers: {
      "X-Site-Id": siteId
    }},
  ).then(
    async (response) => {
      return {
        success: true,
      }
    },
    async (errors) => {
      return {
        success: false,
        content: errors
      }
    },
  );
}