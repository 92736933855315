import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ErrorMessage } from "formik";
import { useState } from "react";

export default function WysiwygField({editorRef, name, labelText, helpText = "",  initialValue = "",  placeholder = "Write the description here...", disabled = false}) {    
  
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ align: [] }],
      ["blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link"],
    ],
  };

  return (
    <>
      <div>
        {labelText && <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-5 mb-2">{labelText}</label>}
        {helpText && <p className="text-gray-600 text-xs italic mb-2">{helpText}</p>}
        <ReactQuill
          id={'quill-editor-' + name}
          theme="snow"
          defaultValue={initialValue}
          modules={modules}
          placeholder={placeholder}
          onChange={(value, delta, source, editor) => {
            editorRef.current = editor;
          }}
          readOnly={disabled}
          required
        />
      </div>
      <ErrorMessage name={name} component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 mb-2"/>
    </>
  );
}