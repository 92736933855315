import React from "react"
import { ErrorMessage, Field } from "formik";

export const CustomField = (props) => {
    const disabledStyling = {
        backgroundColor: '#eaeaea',
        color: '#909090',
    }
    return (
        <div>
            {props.label && 
            <label htmlFor={props.name} className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4 mb-2">
                {props.label}
                {props.required && <span style={{color: "red", paddingLeft: "5px"}}>*</span>}
            </label>}
            <Field
                as={props.as}
                type={props.type}
                name={props.name}
                placeholder={props.placeholder ? props.placeholder : ''}
                multiple={props.multiselect}
                isMulti={props.multiselect}
                checked={props.checked}
                disabled={props.disabled}
                style={props.disabled ? disabledStyling : {}}
                className={props.type === 'checkbox' ? "form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" : "form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-600 focus:outline-none"}
            >
                {props.children}
            </Field>
            {props.type === 'checkbox' && <br />}
            {props.helpText && <p className="text-gray-600 text-xs italic mt-3" style={{marginTop: '5px'}}>{props.helpText}</p>}
            <ErrorMessage name={props.name} component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 mb-2"/>
        </div>
    );
}

export const CustomOptions = (props) => {
    return props?.data.map((item, key) =>
        <>
            {key === 0 && <option key="-1" value="" disabled selected>Select one...</option>}
            <option key={item.id} value={item.id}>{item.name}</option>
        </>
    )
}

export const CustomMultiOptions = (props) => {
    return props?.data.map((item, key) =>
        <>
            <option key={item.id} value={item.id}>{item.name}</option>
        </>
    )
}