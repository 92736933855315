import React, { useState, useEffect } from "react"
import { isLoggedIn } from "../services/auth"
import { handleSignup } from "../services/auth"
import { plutoClient } from "../services/http"
import { PlutoAPIRoutes } from "../constants/pluto"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Alert, Loading } from "./utils";
import { CustomField } from "../elements/form"

const Signup = (props) => {
  const [loading, setLoading] = useState(true);
  const [site, setSite] = useState('');
  const [siteError, setSiteError] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const siteId = props.siteId;

    if (siteId === '') {
      setSiteError(true);
    }

    plutoClient(
      PlutoAPIRoutes.SITES + '/' + siteId,
      'GET',
      {},
      {authHeader: false, headers: {'X-Site-Id': siteId}},
    ).then(
      async (response) => {
        setSite(response);
        setLoading(false);
      },
      async (error) => {
        setSiteError(true);
        setLoading(false);
      },
    );
  }, [props]);

  if (isLoggedIn()) {
    window.location.replace('/dashboard')
  }

  return (
    <div style={{backgroundColor: site.primary_colour ?? site.primary_colour}} className={loading || success || siteError ? 'flex items-center justify-center min-h-screen py-12 bg-white' : 'flex justify-center py-12 px-4 sm:px-6 lg:px-8 bg-white'}>
      <div className="bg-white p-12 md:w-3/4 lg:w-1/2 xl:w-2/5">
      {loading ? <Loading /> :
        <div className="w-full space-y-8">
          {site.logo_url &&
            <div>
              <img
                className="mx-auto h-24 w-auto"
                src={site.logo_url}
                alt={site.name + ' logo'}
              />
            </div>
          }
          {success ? <Alert title="Success!" message="You have successfully signed up. Someone will be in touch soon." success={true} /> :
            siteError ? <Alert title="Oops!" message="Looks like you've entered the incorrect site identifier" /> :
            <>
              <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">{site.name}</h2>
              {error && <Alert title="Oops!" message={error} />}
                <Formik
                  className="mt-8 space-y-6"
                  initialValues={{
                    organisation: '',
                    addressLine1: '',
                    addressLine2: '',
                    addressLine3: '',
                    addressLine4: '',
                    postcode: '',
                    website: '',
                    email: '',
                    phone: '',
                    contactName: '',
                    contactEmail: '',
                    contactPhone: '',
                    contactTitle: '',
                  }}
                  onSubmit={async (values, { setSubmitting, setErrors }) => {
                      const response = await handleSignup(values, props.siteId);

                      setSubmitting(false)

                      if (response.success) {
                        setSuccess(true);
                        setError('');
                        setLoading(false);

                      } else {
                        setError(response.content.message)
                        setErrors(response.content.errors)
                      }
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4 mb-2" for="organisation">
                            Organisation <span style={{color: "red"}}>*</span>
                          </label>
                          <Field type="text" name="organisation" placeholder="Organisation" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" />
                          <ErrorMessage name="organisation" component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-2"/>
                        </div>
                        <div>
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4 mb-2" for="addressLine1">
                            Address Line 1 <span style={{color: "red"}}>*</span>
                          </label>
                          <Field type="text" name="addressLine1" placeholder="Address Line 1" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" />
                          <ErrorMessage name="addressLine1" component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-2"/>
                        </div>
                        <div>
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4 mb-2" for="addressLine2">
                            Address Line 2
                          </label>
                          <Field type="text" name="addressLine2" placeholder="Address Line 2" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" />
                          <ErrorMessage name="addressLine2" component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-2"/>
                        </div>
                        <div>
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4 mb-2" for="addressLine3">
                            Address Line 3 <span style={{color: "red"}}>*</span>
                          </label>
                          <Field type="text" name="addressLine3" placeholder="Address Line 3" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" />
                          <ErrorMessage name="addressLine3" component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-2"/>
                        </div>
                        <div>
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4 mb-2" for="addressLine4">
                            Address Line 4
                          </label>
                          <Field type="text" name="addressLine4" placeholder="Address Line 4" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" />
                          <ErrorMessage name="addressLine4" component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-2"/>
                        </div>
                        <div>
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4 mb-2" for="postcode">
                            Postcode <span style={{color: "red"}}>*</span>
                          </label>
                          <Field type="text" name="postcode" placeholder="Postcode" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" />
                          <ErrorMessage name="postcode" component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-2"/>
                        </div>
                        <div>
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4 mb-2" for="website">
                            Website <span style={{color: "red"}}>*</span>
                          </label>
                          <Field type="text" name="website" placeholder="www.google.com" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" />
                          <ErrorMessage name="website" component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-2"/>
                        </div>
                        <div>
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4 mb-2" for="email">
                            Email Address <span style={{color: "red"}}>*</span>
                          </label>
                          <CustomField type="email" name="email" placeholder="Email address" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" helpText="The email of the organisation" />
                        </div>
                        <div>
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4 mb-2" for="phone">
                            Phone Number <span style={{color: "red"}}>*</span>
                          </label>
                          <Field type="phone" name="phone" placeholder="07123456789" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" />
                          <ErrorMessage name="phone" component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-2"/>
                        </div>
                        <div>
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4 mb-2" for="contactName">
                            Contact Name <span style={{color: "red"}}>*</span>
                          </label>
                          <Field type="text" name="contactName" placeholder="Contact name" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" />
                          <ErrorMessage name="contactName" component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-2"/>
                        </div>
                        <div>
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4 mb-2" for="contactEmail">
                            Contact Email <span style={{color: "red"}}>*</span>
                          </label>
                          <CustomField type="email" name="contactEmail" placeholder="Contact email" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" helpText="The email of the primary contact - used to login and receive updates" />
                        </div>
                        <div>
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4 mb-2" for="contactPhone">
                            Contact Phone <span style={{color: "red"}}>*</span>
                          </label>
                          <Field type="phone" name="contactPhone" placeholder="Contact phone" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" />
                          <ErrorMessage name="contactPhone" component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-2"/>
                        </div>
                        <div>
                          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4 mb-2" for="contactTitle">
                            Contact Job Title
                          </label>
                          <Field type="text" name="contactTitle" placeholder="Contact Job Title" className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" />
                          <ErrorMessage name="contactTitle" component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-2"/>
                        </div>
                        <div>
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            style={{backgroundColor: site.primary_colour ?? site.primary_colour}}
                            className="group relative w-full flex justify-center my-3 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                          >
                            Sign up
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
            </>
          }
        </div>
        }
      </div>
    </div>
  );
}

export default Signup;
