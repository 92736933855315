import React, { useState, useEffect, useRef } from "react"
import { plutoClient } from "../services/http"
import { PlutoAPIRoutes } from "../constants/pluto"
import { getSite } from "../services/utils"
import WysiwygField from "../elements/WysiwygField"
import { Loading } from "./utils"
import { Form, Formik } from "formik"
import swal from "sweetalert"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CustomField } from "../elements/form"
import { getOrganisation } from "../requests/organisation"

const Organisation = (props) => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [organisation, setOrganisation] = useState({});
  const [setSubmitting, submitting] = useState(false);

  const emailEditor = useRef(null);

  const site = getSite();
  
  const updateOrganisation = async (content) => {
    return await plutoClient(
      PlutoAPIRoutes.EMPLOYABILITY_ORGANISATION + '/' + organisation.id,
      'PUT',
      content,
      {authHeader: true, siteId: true, headers: {}},
      false,
    ).then(
      async (response) => { 
          return {
            success: true,
          }
      },
      async (errors) => {
          return {
            success: false,
            content: errors
          }
      },
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      setOrganisation(await getOrganisation())
    }

    fetchData().then(() => {
      setLoading(false)
    })
  }, []);

  if (loading) {
    return (
      <>
        <Loading />
      </>
    )
  }

  if (error) {
    return (
      <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong class="font-bold">Oops! </strong>
        <span class="block sm:inline">{error}</span>
      </div>
    )
  }

  return (
    <>
      {success && 
        <div class="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800" role="alert">
          <span class="font-medium">Success!</span> Your organisation has been updated successfully
        </div>
      }
      <div className="bg-white p-5 rounded-lg mt-5">
        <h1 className="text-xl font-bold mb-5">Update Organisation Information</h1>
        <Formik
          className="mt-8 space-y-6"
          enableReinitialize={true}
          initialValues={{
            default_interest_email: organisation.default_interest_email,
            name: organisation.name,
            contact_name: organisation.contact_name,
            contact_email_address: organisation.contact_email_address,
          }}
          validate={values => {
            const errors = {};
            return errors;
          }}
          onSubmit={async (values, { setSubmitting, setErrors }) => {

            values.default_interest_email = emailEditor.current.getContent();

            let response = await updateOrganisation(values);
            
            setSubmitting(false)

            if (response.success) {
              setSuccess(true)
            } else {
              setErrors(response.content.errors);
              setSuccess(false)
              swal({
                title: "Please check what you've entered",
                text: "Please check the highlighted form fields containing errors and try again.",
                icon: "warning",
              });
            }
          }}
        >
        {({ isSubmitting }) => (
          <Form>
            <CustomField as="input" type="text" name="name" label="Organisation Name" disabled={true} />
            <CustomField as="input" type="text" name="contact_name" label="Contact Name" disabled={true} />
            <CustomField as="input" type="text" name="contact_email_address" label="Contact Email Address" disabled={true} />
            <WysiwygField name="default_interest_email" labelText="Default Email" helpText="This default content will be sent to all interested members of your opportunities unless otherwise overwritten against the individual opportunity" editorRef={emailEditor} initialValue={organisation.default_interest_email} enabled={true} />

            <button style={{backgroundColor: site.primary_colour ?? '#999'}} type="submit" disabled={isSubmitting} className="mt-4 mb-2 inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-normal uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
              {isSubmitting ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Update'}
            </button>
          </Form>
        )}
        </Formik>
      </div>
    </>
  );
}

export default Organisation