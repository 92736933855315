import React, { useState, useEffect } from "react"
import DataTable from 'react-data-table-component'
import { plutoClient } from "../services/http"
import { PlutoAPIRoutes } from "../constants/pluto"
import { getSite } from "../services/utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons'
import { Loading } from "./utils"
import { useNavigate } from "react-router";

const selectProps = { indeterminate: (isIndeterminate) => isIndeterminate };

const Opportunities = (props) => {
  const [opportunities, setOpportunities] = useState([]);
  const [pendingOpportunities, setPendingOpportunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const site = getSite();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await plutoClient(
        PlutoAPIRoutes.EMPLOYABILITY_OPPORTUNITIES + '?perPage=1000&forOrganisation=1&approved=1',
        'GET',
        {},
        {authHeader: true, siteId: true, headers: {}},
        true,
      ).then(
        async (response) => {
          setOpportunities(response);
        },
        async (error) => {
          setError('We are unable to show you this right now');
        },
      );
  
      await plutoClient(
        PlutoAPIRoutes.EMPLOYABILITY_OPPORTUNITIES + '?perPage=1000&forOrganisation=1&pending=1',
        'GET',
        {},
        {authHeader: true, siteId: true, headers: {}},
        true,
      ).then(
        async (response) => {
          setPendingOpportunities(response);
        },
        async (error) => {
          setError('We are unable to show you this right now');
        },
      );
    }

    fetchData().then(() => {
      setLoading(false)
    })
  }, []);

  if (loading) {
    return (
      <>
        <Loading />
      </>
    )
  }

  if (error) {
    return (
      <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        <strong class="font-bold">Oops! </strong>
        <span class="block sm:inline">{error}</span>
      </div>
    )
  }

  return (
    <>
      <div className="bg-white p-5 rounded-lg mt-5">
        <h1 className="text-xl font-bold mb-5">Pending Opportunities <button style={{backgroundColor: site.primary_colour ?? '#fff'}} className="text-white font-small text-xs py-1 px-2 rounded float-right" onClick={() => navigate('/opportunities/create')}>Create</button></h1>
        <DataTable
          responsive
          striped
          pagination
          selectableRowsComponentProps={selectProps}
          columns={[
            {
                name: 'Name',
                selector: row => row.name,
            },
            {
              name: 'Status',
              cell: row => <span className={`text-xs font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1 ${row?.review_decision === 'D' ? 'text-red-600 bg-red-200' : 'text-yellow-600 bg-yellow-200'}`}>{row?.review_decision === 'D' ? 'Declined' : 'Pending'}</span>,
            },
            {
              name: 'Feedback',
              cell: row => <span className={`text-xs font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1 ${row?.hasFeedback ? 'text-yellow-600 bg-yellow-200' : 'text-gray-600 bg-gray-200'}`}>{row?.hasFeedback ? 'Yes' : 'No'}</span>,
            },
            {
              name: 'Actions',
              cell: row => <button style={{backgroundColor: site.primary_colour ?? '#fff'}} className="text-white font-bold py-2 px-4 rounded" onClick={() => navigate('/opportunities/view/' + row.id)} id={row.id}><FontAwesomeIcon icon={faExternalLinkSquareAlt} /></button>,
              button: true,
            }
          ]}
          data={pendingOpportunities}
        />
      </div>

      <div className="bg-white p-5 rounded-lg mt-10">
        <h1 className="text-xl font-bold mb-5">Current Opportunities</h1>
        <DataTable
          responsive
          striped
          pagination
          selectableRowsComponentProps={selectProps}
          columns={[
            {
                name: 'Name',
                selector: row => row.name,
            },
            {
                name: 'Places',
                selector: row => row.taken_places,
                selector: row => <span className={`text-xs font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1 text-green-600 bg-green-200`}>{row?.taken_places + '/' + row?.available_places}</span>,
            },
            {
                name: 'Actions',
                cell: row => <button style={{backgroundColor: site.primary_colour ?? '#fff'}} className="text-white font-bold py-2 px-4 rounded" onClick={() => navigate('/opportunities/view/' + row.id)} id={row.id}><FontAwesomeIcon icon={faExternalLinkSquareAlt} /></button>,
                button: true,
            }
          ]}
          data={opportunities}
        />
      </div>
    </>
  );
}

export default Opportunities