import { plutoClient } from "../services/http"
import { PlutoAPIRoutes } from "../constants/pluto"
import { getSite } from "../services/utils";

const site = getSite();

export const createOpportunity = async (values) => {
    return await plutoClient(
        PlutoAPIRoutes.EMPLOYABILITY_OPPORTUNITIES,
        'POST',
        values,
        {authHeader: true, headers: {'X-Site-Id': site.id}},
    ).then(
        async (response) => { 

            return {
                success: true,
            }
        },
        async (errors) => {
            return {
                success: false,
                content: errors
            }
        },
    );
}

export const modifyOpportunity = async (values, id) => {
    return await plutoClient(
        PlutoAPIRoutes.EMPLOYABILITY_OPPORTUNITIES + '/' + id,
        'PUT',
        values,
        {authHeader: true, headers: {'X-Site-Id': site.id}},
    ).then(
        async (response) => { 
            return {
                success: true,
            }
        },
        async (errors) => {
            return {
                success: false,
                content: errors
            }
        },
    );
}

export const getLocations = async (values) => {
    return await plutoClient(
        PlutoAPIRoutes.EMPLOYABILITY_LOCATIONS,
        'GET',
        {},
        {authHeader: false, headers: {'X-Site-Id': site.id}},
    ).then(
        async (response) => { 
            return response
        },
        async (errors) => {
            return {
                success: false,
                content: errors
            }
        },
    );
}

export const getOpportunity = async (id) => {
    return await plutoClient(
        PlutoAPIRoutes.EMPLOYABILITY_OPPORTUNITIES + '/' + id + '?dateFormat=Y-m-d',
        'GET',
        {},
        {authHeader: true, headers: {'X-Site-Id': site.id}},
    ).then(
        async (response) => { 
            return {
                success: true,
                content: response
            }
        },
        async (errors) => {
            return {
                success: false,
                content: errors
            }
        },
    );
}

export const getSkills = async (values) => {
    return await plutoClient(
        PlutoAPIRoutes.EMPLOYABILITY_SKILLS + '?perPage=1000',
        'GET',
        {},
        {authHeader: false, headers: {'X-Site-Id': site.id}},
        false,
        true,
    ).then(
        async (response) => { 
            return response
        },
        async (errors) => {
            return {
                success: false,
                content: errors
            }
        },
    );
}

export const getCategories = async (values) => {
    return await plutoClient(
        PlutoAPIRoutes.EMPLOYABILITY_CATEGORIES + '?perPage=1000',
        'GET',
        {},
        {authHeader: false, headers: {'X-Site-Id': site.id}},
        false,
        true,
    ).then(
        async (response) => { 
            return response
        },
        async (errors) => {
            return {
                success: false,
                content: errors
            }
        },
    );
}

export const getSchools = async (values) => {
    return await plutoClient(
        PlutoAPIRoutes.SCHOOLS + '?perPage=1000',
        'GET',
        {},
        {authHeader: false, headers: {'X-Site-Id': site.id}},
        false,
        true,
    ).then(
        async (response) => { 
            return response
        },
        async (errors) => {
            return {
                success: false,
                content: errors
            }
        },
    );
}

export const getFrequencies = async (values) => {
    return await plutoClient(
        PlutoAPIRoutes.EMPLOYABILITY_FREQUENCIES + '?perPage=1000',
        'GET',
        {},
        {authHeader: false, headers: {'X-Site-Id': site.id}},
        false,
        true,
    ).then(
        async (response) => { 
            return response
        },
        async (errors) => {
            return {
                success: false,
                content: errors
            }
        },
    );
}