import React, { useEffect, useRef, useState } from "react"
import { Form, Formik } from "formik"
import { getSite, uriSegment } from "../services/utils";
import { CustomField, CustomOptions, CustomMultiOptions } from '../elements/form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { createOpportunity, getCategories, getLocations, getOpportunity, getSkills, getFrequencies, modifyOpportunity } from "../requests/opportunities";
import { getOrganisation } from "../requests/organisation";
import { Loading } from "./utils";
import FourOFour from "./404";
import WysiwygField from "../elements/WysiwygField";
import swal from "sweetalert";
import { getSettings } from "../requests/generic";

const OpportunitiesForm = () => {
  const isModify = uriSegment(2) === 'view'

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [organisation, setOrganisation] = useState();
  const [opportunity, setOpportunity] = useState([]);
  const [locations, setLocations] = useState([]);
  const [skills, setSkills] = useState([]);
  const [categories, setCategories] = useState([]);
  const [editorEnabled, setEditorEnabled] = useState(false);
  const [frequencies, setFrequencies] = useState(null);

  // TinyMCE Edtor Refs
  const tasksEditor = useRef(null);
  const locationEditor = useRef(null);
  const supportEditor = useRef(null);
  const qualitiesEditor = useRef(null);
  const availabilityEditor = useRef(null);
  const additionalEditor = useRef(null);
  const emailEditor = useRef(null);
  
  useEffect(async () => {
    if(isModify) {
      const response = await getOpportunity(uriSegment(3))
      if (!response.success) {
        setLoading(false)
        setError(true);
      } else {
        setOpportunity(response.content)
      }
    }

    const fetchData = async () => {
      setOrganisation(await getOrganisation())
      setLocations(await getLocations())
      setSkills(await getSkills())
      setCategories(await getCategories())
      setFrequencies(await getFrequencies())
      configureSettings(await getSettings([286]))
    }

    fetchData().then(() => {
      setLoading(false)
    })
  }, []);

  const configureSettings = (settings) => {
    setEditorEnabled(settings[286] === 'Y')
  }

  const formatItems = (items) => {
    let response = []

    if (items != undefined && items.length > 0) {
      items.forEach(item => {
        response.push(item.id.toString())
      });
    }

    return response
  }

  const site = getSite()

  const isApproved = isModify && opportunity.review_decision === 'A'
  const opportunityStatus = opportunity.review_decision;
  const disabledInputs = (isModify && isApproved) || (isModify && opportunityStatus === null && opportunity.feedback === null);

  if (loading) {
    return (
      <>
        <Loading />
      </>
    )
  }

  if (error) {
    return (
      <FourOFour />
    )
  }

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  };

  return (
    <div>
      {isModify &&
      <div className={`border  px-4 py-3 rounded relative ${opportunity.review_decision === 'A' ? 'bg-green-100 border-green-400 text-green-700' : opportunity.review_decision === 'D' ? 'bg-red-100 border-red-400 text-red-700' : 'bg-yellow-100 border-yellow-400 text-yellow-700'}`} role="alert">
        <strong className="font-bold">{opportunity.review_decision === 'A' ? 'Approved! ' : opportunity.review_decision === 'D' ? 'Declined! ' : 'Pending! '}</strong>
        <span className="block sm:inline">{opportunity.review_decision === 'A' ? 'This opportunity has been approved and is now available to publish on the website' : opportunity.review_decision === 'D' ? 'This opportunity has been declined. Please refer to feedback, make changes and resubmit' : 'This opportunity is awaiting review'}</span>

        {opportunity.feedback && 
          <>
            <br />
            <br />
            <strong className="font-bold">Feedback: </strong>
            <span className="block sm:inline">{opportunity.feedback}</span>
          </>
        }
      </div>
      }
      <div className="bg-white p-5 rounded-lg mt-5 px-10">
      <Formik
          className="mt-8 space-y-6"
          enableReinitialize={true}
          initialValues={isModify ? {
            name: opportunity.name,
            location: opportunity.location_id,
            skills: {"": formatItems(opportunity.skills)},
            categories: {"": formatItems(opportunity.categories)},
            start_date: opportunity.start_date,
            end_date: opportunity.end_date,
            frequency: opportunity.frequency.id,
            available_places: opportunity.available_places,
            dbs: Boolean(opportunity.dbs),
            tasks: opportunity.tasks,
            location_details: opportunity.location,
            support: opportunity.support,
            qualities: opportunity.qualities,
            availability: opportunity.availability,
            additional_information: opportunity.additional_information,
            initial_interest_email: organisation.initial_interest_email,
          } : {
            name: '',
            location: '',
            skills: '',
            categories: '',
            start_date: '',
            end_date: '',
            frequency: '',
            available_places: '',
            dbs: '',
            tasks: '',
            location_details: '',
            support: '',
            qualities: '',
            availability: '',
            additional_information: '',
            initial_interest_email: organisation.initial_interest_email,
          }}
          validate={values => {
            const errors = {};
            return errors;
          }}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            
            let response = [];

            // Add image file if given one
            if(values.hasOwnProperty('file'))
            {
              values.file = await readFileAsync(values.file);
            }
            
            // Add WYSIWYG values
            values.tasks = tasksEditor.current != null ? tasksEditor.current.getHTML() : "";
            values.location_details = locationEditor.current != null ? locationEditor.current.getHTML() : "";
            values.support = supportEditor.current != null ? supportEditor.current.getHTML() : "";
            values.qualities = qualitiesEditor.current != null ? qualitiesEditor.current.getHTML() : "";
            values.availability = availabilityEditor.current != null ? availabilityEditor.current.getHTML() : "";
            values.additional_information = additionalEditor.current != null ? additionalEditor.current.getHTML() : "";
            values.initial_interest_email = emailEditor.current != null ? emailEditor.current.getHTML() : "";

            if (isModify) {
              response = await modifyOpportunity(values, opportunity.id);
            } else {
              response = await createOpportunity(values);
            }
            
            setSubmitting(false)

            if (response.success) {
              if (isModify) {
                window.location.reload()
              } else {
                window.location.replace('/opportunities')
              }
            } else {
              setErrors(response.content.errors);
              swal({
                title: "Some fields need attention",
                text: "Please check the highlighted form fields containing errors and try again.",
                icon: "warning",
              });
            }
          }}
        >
          {({ setFieldValue, isSubmitting, values }) => (
            <Form>
              <div className="rounded-md -space-y-px">
                <CustomField as="input" type="text" name="name" label="Name" disabled={disabledInputs} helpText="Title of the volunteering role" required />

                {/* Opportunity Thumbnail */}
                <label htmlFor="thumbnailUpload" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4 mb-2" style={{marginTop: '15px'}}>
                  Thumbnail
                </label>
                <input id="file" name="file" type="file" accept="image/*" onChange={(event) => setFieldValue("file", event.currentTarget.files[0])} style={{marginTop: '5px'}} />
                <p className="text-gray-600 text-xs italic mt-3" style={{marginTop: '5px'}}>Please enquire with your associated Students' Union regarding the appropriate image dimensions</p>
                
                <CustomField as="select" type="select" name="location" label="Location" disabled={disabledInputs} required>
                  <CustomOptions data={locations} />
                </CustomField>
                <CustomField as="select" type="select" name="skills[]" label="Skills" disabled={disabledInputs} helpText="Use ctrl/cmd key to select multiple" multiselect>
                  <CustomMultiOptions data={skills} multiselect />
                </CustomField>
                <CustomField as="select" type="select" name="categories[]" label="Categories" disabled={disabledInputs} helpText="Use ctrl/cmd key to select multiple" multiselect>
                  <CustomMultiOptions data={categories} multiselect />
                </CustomField>
                <CustomField as="input" type="date" name="start_date" label="Start Date" disabled={disabledInputs} helpText="The date the opportunity should appear on the site" required />
                <CustomField as="input" type="date" name="end_date" label="End Date" disabled={disabledInputs} helpText="The date the opportunity should be removed from the site" required />
                {frequencies.length > 0 && <CustomField as="select" type="select" name="frequency" label="Frequency" disabled={disabledInputs} helpText="How often the role is">
                  <CustomOptions data={frequencies} />
                </CustomField>}
                <CustomField as="input" type="number" name="available_places" label="Available Places" disabled={disabledInputs} helpText="For unlimited places, put -1" required />
                
                <CustomField type="checkbox" name="dbs" label="DBS/PVG Required?" checked={values.dbs} disabled={disabledInputs}/>

                <WysiwygField name="tasks" labelText="Tasks" editorRef={tasksEditor} initialValue={opportunity.tasks} disabled={disabledInputs} helpText="Outline the tasks and duties of this role" enabled={editorEnabled} />
                <WysiwygField name="location_details" labelText="Location Details" editorRef={locationEditor} initialValue={opportunity.location} disabled={disabledInputs} helpText="Details about the location of the opportunity (Address, postcode, etc.)" enabled={editorEnabled} />
                <WysiwygField name="support" labelText="Support" editorRef={supportEditor} initialValue={opportunity.support} disabled={disabledInputs} helpText="What support, training and recognition is offered to volunteers?" enabled={editorEnabled} />
                <WysiwygField name="qualities" labelText="Qualities" editorRef={qualitiesEditor} initialValue={opportunity.qualities} disabled={disabledInputs} helpText="What skills or qualities are required for this role?" enabled={editorEnabled} />
                <WysiwygField name="availability" labelText="Availability" editorRef={availabilityEditor} initialValue={opportunity.availability} disabled={disabledInputs} helpText="The availability volunteers should have for this opportunity" enabled={editorEnabled} />
                <WysiwygField name="additional_information" labelText="Additional Information" editorRef={additionalEditor} initialValue={opportunity.additional_information} disabled={disabledInputs} helpText="Who would this role be most suited to? Timescale from application to starting volunteering? When can students expect to hear back from you once they have been referred?" enabled={editorEnabled} />
                <WysiwygField name="initial_interest_email" labelText="Initial Interest Email" editorRef={emailEditor} initialValue={opportunity.initial_interest_email == null ? organisation.default_interest_email : opportunity.initial_interest_email} disabled={disabledInputs} helpText="This content will be emailed directly to any student that registers their interest in this opportunity. Any pre-populated details are fed through from your organisation's default email content" enabled={true} />                

              </div>
              <div>
                {/* Create submit */}
                {!isModify && 
                  <button style={{backgroundColor: site.primary_colour ?? '#999'}} type="submit" disabled={isSubmitting} className="mt-4 mb-2 inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-normal uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                    {isSubmitting ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Submit'}
                  </button>
                }

                {/* Modify submit */}
                {isModify && (opportunity.feedback != null && opportunity.feedback !== '') && opportunity.review_decision !== 'A' && !isApproved &&
                  <button style={{backgroundColor: site.primary_colour ?? '#999'}} type="submit" disabled={isSubmitting} className="mt-4 mb-2 inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-normal uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                    {isSubmitting ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Submit'}
                  </button>
                }
              </div>
            </Form>
          )}
        </Formik>
    </div>
  </div>
  )
}

export default OpportunitiesForm