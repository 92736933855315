import React from "react"
import { getUser, isLoggedIn, logout } from "../services/auth"
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon, LogoutIcon } from '@heroicons/react/outline'
import { useNavigate } from "react-router"

const isCurrent = url => {
  return window.location.pathname === url
}

const site = JSON.parse(window.localStorage.getItem("plutoSite"))

export default function NavBar() {
  let navigate = useNavigate();

  const navigation = [
    { name: 'Dashboard', href: () => navigate('/dashboard'), current: isCurrent('/dashboard') },
    { name: 'Opportunities', href: () => navigate('/opportunities'), current: isCurrent('/opportunities') },
    { name: 'Placements', href: () => navigate('/placements'), current: isCurrent('/placements') },
    { name: 'My Organisation', href: () => navigate('/organisation'), current: isCurrent('/organisation') },
  ]

  return (
    <Disclosure as="nav" style={{backgroundColor: site.primary_colour ?? '#999'}} className="bg-sums-secondary-600">
      {({ open }) => (
        <>
          <div className="max-w-[90rem] mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="hidden md:block">
                  <div className="flex items-baseline space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        onClick={item.href}
                        className="text-white cursor-pointer px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <a
                    onClick={() => {
                      logout()
                    }}
                    className="text-white cursor-pointer px-3 py-2 rounded-md text-sm font-medium cursor-pointer"
                  >
                    <LogoutIcon className="block h-5 w-5" aria-hidden="true" />
                  </a>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  onClick={item.href}
                  className="block px-3 py-2 rounded-md text-base font-medium text-white"
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}