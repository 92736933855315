import { plutoClient } from "../services/http"
import { PlutoAPIRoutes } from "../constants/pluto"
import { getSite } from "../services/utils";

const site = getSite();

export const getEmployabilityDetails = async () => {
    return await plutoClient(
        PlutoAPIRoutes.EMPLOYABILITY,
        'GET',
        {},
        {authHeader: false, headers: {'X-Site-Id': site.id}},
    ).then(
        async (response) => { 
            return response
        },
        async (errors) => {
            return {
                success: false,
                content: errors
            }
        },
    );
}