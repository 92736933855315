import {PlutoAPIUri} from '../constants/pluto';
import { logout } from './auth';

/**
 * Based upon a fetch wrapper
 * @see https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper
 */

/**
 * Http client request for fetch
 *
 * @param string url
 * @param string method
 * @param object body
 * @param object customConfig
 */
export function httpClient(
  url,
  method,
  body = {},
  customConfig = {},
  forDataTable = false,
  returnOnlyData = true,
) {
  // Default headers
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  // Determine config and merge with custom config
  const config = {
    method: method,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  // If contains a body, JSON it
  if (
    body &&
    (method === 'POST' ||
      method === 'PUT' ||
      method === 'DELETE')
  ) {
    config.body = JSON.stringify(body);
  }

  // Run the fetch
  return fetch(url, config)
    .then(async (response) => {
      const data = await response.json();

      if (response.ok) {
        // If data is wrapped in data, then remove the data
        if (data.data && returnOnlyData) {

          if (forDataTable) {
            return Object.values(data.data);
          }

          return data.data;
        }

        if (forDataTable) {
          return Object.values(data);
        } else {
          return data;
        }
      } else {
        const invalidCodes = [401, 403];
        if (invalidCodes.includes(response.status)) {
          logout(true)
        }
        // @TODO work on error handling of requests
        return Promise.reject(data);
      }
    })
    .catch((error) => {
      // @TODO work on error handling of requests
      return Promise.reject(error);
    });
}

/**
 * Http client for the main pluto api
 *
 * @param string endpoint
 * @param string method
 * @param object body
 * @param object plutoConfig
 */
export function plutoClient(
  endpoint,
  method,
  body = {},
  plutoConfig = {},
  forDataTable = false,
  returnOnlyData = true,
) {
  let headers = {};

  // If we require authentication then add the bearer token
  if (plutoConfig.authHeader) {
    const auth = JSON.parse(window.localStorage.getItem("plutoUser"))

    headers.Authorization = `Bearer ${auth.access_token}`;
  }

  if (plutoConfig.siteId) {
    const site = JSON.parse(window.localStorage.getItem("plutoSite"))

    headers['X-Site-Id'] = site.id;
  }

  // Build the new config for pluto with the auth header
  const config = {
    ...plutoConfig,
    headers: {
      ...headers,
      ...plutoConfig.headers,
    },
  };

  // Return http client with pluto url and endpooint
  return httpClient(
    PlutoAPIUri + `/${endpoint}`,
    method,
    body,
    config,
    forDataTable,
    returnOnlyData,
  );
}
