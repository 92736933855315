import { isLoggedIn } from "../services/auth"

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function generateTitle(url) {
    if (!isLoggedIn()) {
        if (url.includes('signup')) {
            return 'Signup';
        }
    
        if (url.includes('login')) {
            return 'Login';
        }

        if (url.includes('forgot-password')) {
            return 'Forgot Password';
        }

        return 'Page Not Found';
    } else {
        if (url.includes('/opportunities/view')) {
            return 'View Opportunity';
        }

        switch (url) {
            case '/dashboard':
                return 'Dashboard'
                break;
            case '/opportunities':
                return 'Opportunities'
                break;
            case '/placements':
                return 'Placements'
                break;
            case '/opportunities/create':
                return 'Create Opportunity'
                break;
            case '/organisation':
                return 'My Organisation'
                break;
            default:
                return 'Page Not Found'
                break;
        }
    }
}

export function getSite() {
    return JSON.parse(window.localStorage.getItem("plutoSite"))
}

export function uriSegment(segment = 0) {
    const path = window.location.pathname.split('/')
    
    return path[segment];
}

export function greeting() {
    const currentHour = new Date().getHours();
  
    if (currentHour >= 0 && currentHour <= 11) {
      return 'Good Morning';
    } else if (currentHour >= 12 && currentHour <= 17) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  }
  
export const isBrowser = () => typeof window !== "undefined"