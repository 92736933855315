import React, { useEffect, useState } from "react"
import { getSite } from "../services/utils";
import { getEmployabilityDetails } from "../requests/employability";
import { Loading } from "./utils";
import { useNavigate } from "react-router-dom";

const site = getSite();

const Dashboard = () => {
  const [employability, setEmployability] = useState({})
  const [loading, setLoading] = useState(true)

  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getEmployabilityDetails();

      setEmployability(data)
    }

    fetchData().then(() => {
      setLoading(false)
    })
  }, []);

  if (loading) {
    return (
      <>
        <Loading />
      </>
    )
  }

  return (
    <>
      {employability?.key_contacts.length != 0 &&
        <div class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-5" role="alert">
          <p class="font-bold">Key Contacts</p>
          {employability.key_contacts.map((contact) => <p>{contact.name}: <a href={'mailto:' + contact.email}>{contact.email}</a></p>)}
        </div>
      }
      <div className="flex space-x-4 flex-grow">
        <div className="max-w-md bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
          <div className="md:flex">
            <div className="p-8">
              <div style={{color: site.primary_colour ?? '#999'}} className="uppercase tracking-wide text-sm text-sums-500 font-semibold">Opportunities</div>
              <a onClick={() => navigate('/opportunities')} className="block mt-1 text-lg leading-tight font-medium text-black hover:underline cursor-pointer">Create and modify opportunities</a>
              <p className="text-gray-500">Create new opportunities for students to register interest in</p>
            </div>
          </div>
        </div>
        <div className="max-w-md bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
          <div className="md:flex">
            <div className="p-8">
              <div style={{color: site.primary_colour ?? '#999'}} className="uppercase tracking-wide text-sm text-sums-500 font-semibold">Placements</div>
              <a onClick={() => navigate('/placements')} className="block mt-1 text-lg leading-tight font-medium text-black hover:underline cursor-pointer">Manage student placements</a>
              <p className="text-gray-500">Interact with students who are currently enrolled in placements</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard