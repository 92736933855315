export const PlutoAPIUri = 'https://pluto.sums.su/api';

export const PlutoAPIRoutes = {
  SITES: 'sites',
  SIGNUP: 'employability/organisation/signup',
  LOGIN: 'authentication/generic',
  LOGOUT: 'authentication/expire',
  EMPLOYABILITY: 'employability',
  EMPLOYABILITY_ORGANISATION: 'employability/organisations',
  EMPLOYABILITY_OPPORTUNITIES: 'employability/opportunities',
  EMPLOYABILITY_LOCATIONS: 'employability/locations',
  EMPLOYABILITY_SKILLS: 'employability/skills',
  EMPLOYABILITY_CATEGORIES: 'employability/categories',
  EMPLOYABILITY_PLACEMENTS: 'employability/placements',
  SCHOOLS: 'schools',
  EMPLOYABILITY_FREQUENCIES: 'employability/frequencies',
  RESET_PASSWORD: 'forgot-password',
  SETTINGS: 'settings',
};
