import React from "react"
import { Routes, Route } from "react-router-dom";
import Layout from "./components/layout"
import Dashboard from "./components/dashboard"
import Organisation from './components/organisation'
import Opportunities from './components/opportunities'
import OpportunitiesForm from './components/opportunities-form'
import Placements from './components/placements'
import Login from "./components/login"
import ForgotPassword from "./components/forgot-password"
import Signup from "./components/signup"
import PrivateRoute from "./components/private-route"
import FourOFour from "./components/404"

const App = () => (
  <Layout>
    <Routes>
      <Route path="/signup/:siteId" element={<Signup />} />
      <Route path="/login/:siteId" element={<Login />} />
      <Route path="/forgot-password/:siteId" element={<ForgotPassword />} />
      
      <Route path="*" exact={true} element={<FourOFour />} />

      <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
      <Route path="/organisation" element={<PrivateRoute component={Organisation} />} />
      <Route path="/opportunities" element={<PrivateRoute component={Opportunities} />} />
      <Route path="/opportunities/create" element={<PrivateRoute component={OpportunitiesForm} />} />
      <Route path="/opportunities/view/:opportunityId" element={<PrivateRoute component={OpportunitiesForm} />} />
      <Route path="/placements" element={<PrivateRoute component={Placements} />} />
    </Routes>
  </Layout>
)

export default App