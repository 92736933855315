import React from "react"
import { getSite } from "../services/utils";

const site = getSite();

const FourOFour = () => (
  <div className="flex h-screen items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div className="text-center">
      <h1 style={{color: site?.primary_colour ?? '#999'}} className="text-9xl text-bold text-sums-500">404</h1>
      <p className="text-2xl mt-10">Sorry, we can't find what you were looking for</p>
    </div>
  </div>
)

export default FourOFour