import React, { useState, useEffect } from "react"
import { handleLogin, isLoggedIn } from "../services/auth"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Alert, Loading } from "./utils";
import { plutoClient } from "../services/http"
import { PlutoAPIRoutes } from "../constants/pluto"
import { useNavigate, useLocation } from "react-router";
 
const Login = (props) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [site, setSite] = useState('');
  const [sites, setSites] = useState('');

  const search = useLocation().search
  const hasResetPassword = new URLSearchParams(search).get('password_reset');
  const sessionExpired = new URLSearchParams(search).get('session_expired');
  
  let navigate = useNavigate();

   if (isLoggedIn()) {
    window.location.replace('/dashboard')
  }

  useEffect(() => {
    if (props.siteId) {
      plutoClient(
        PlutoAPIRoutes.SITES + '/' + props.siteId,
        'GET',
        {},
        {authHeader: false, headers: {'X-Site-Id': props.siteId}},
      ).then(
        async (response) => {
          setSite(response);
          setLoading(false);
        },
        async (error) => {
          setError('Invalid Site ID Parameter');
          setLoading(false);
        },
      );
    } else {
      plutoClient(
        PlutoAPIRoutes.SITES,
        'GET',
        {},
        {authHeader: false, headers: {}},
      ).then(
        async (response) => {
          setSites(response);
          setLoading(false);
        },
        async (error) => {
          setError('Oops, we are unable to show you this right now');
          setLoading(false);
        },
      );
    }
  }, [props]);

  return (
    <div style={{backgroundColor: site.primary_colour ?? ''}} className="flex items-center justify-center min-h-screen">
      <div className="px-8 py-6 mt-4 text-left md:w-1/2 lg:w-1/4" style={{backgroundColor: site ? '#fff' : ''}}>
        {loading ? <Loading /> :
          props.siteId ?
            <>
            {site.logo_url &&
              <div>
                <img
                  className="mx-auto h-24 w-auto mb-8"
                  src={site.logo_url}
                  alt={site.name + ' logo'}
                />
              </div>
            }
            <h3 className="my-5 text-xl text-center leading-6 font-medium text-gray-900">Sign in</h3>
            {sessionExpired ? <Alert title="Oops!" message="Your session has expired, please sign in again to continue"/> : ''}
            {error ? <Alert title="Oops!" message={error} /> : ''}
            {hasResetPassword ? <Alert title="Success!" message="Your password has been reset" success/> : ''}
              <Formik
                className="mt-8 space-y-6"
                initialValues={{ email: '', password: '' }}
                validate={values => {
                  const errors = {};
                  if (!values.password) {
                    errors.password = 'required';
                  }
                  if (!values.email) {
                    errors.email = 'required';
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                  ) {
                    errors.email = 'Invalid email address';
                  }
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    const response = await handleLogin(values, site);
                    
                    setSubmitting(false)

                    if (response.success) {
                      window.location.replace('/dashboard')
                    } else {
                      setError(response.content.message)
                      setErrors(response.content.errors)
                    }
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="rounded-md -space-y-px">
                      <div>
                        <label htmlFor="email-address" className="sr-only">
                          Email address
                        </label>
                        <Field type="email" name="email" placeholder="Email address" className="form-control
                          block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" />
                        <ErrorMessage name="email" component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-2"/>
                      </div>
                      <div>
                        <label htmlFor="password" className="sr-only">
                          Password
                        </label>
                        <Field type="password" name="password" placeholder="Password" className="block
                          w-full
                          px-3
                          py-1.5
                          text-base
                          font-normal
                          text-gray-700
                          bg-white bg-clip-padding
                          border border-solid border-gray-300
                          rounded
                          transition
                          ease-in-out
                          m-0
                          mt-5
                          focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" />
                        <ErrorMessage name="password" component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-2"/>
                      </div>
                      <div>
                        <button style={{backgroundColor: site.primary_colour ?? '#999'}} type="submit" disabled={isSubmitting} className="mt-4 mb-2 w-full inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-normal uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                          Sign in
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              <a href={"/forgot-password/" + site.id} className="flex justify-center mt-3 text-sm" style={{color: site.primary_colour ?? '#999'}}>Forgot password?</a>
            </> : 
            <>
              <div className="flex flex-col justify-center items-center">
                <div className="items-center">
                  <h3 className="text-xl leading-6 font-medium text-gray-900">Select a Union</h3>
                  <div className="my-3 xl:w-96">
                    <select onChange={(selection) => navigate('/login/' + selection.target.value)} className="form-select appearance-none
                      block
                      w-full
                      px-3
                      py-1.5
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding bg-no-repeat
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-gray-600 focus:outline-none">
                        <option selected>Choose one...</option>
                        {sites.map((site) => {
                          return <option value={site.id}>{site.name}</option>
                        })}
                    </select>
                  </div>
                </div>
              </div>
              </>
        }
        </div>
    </div>
  );
};

export default Login