import React from "react"
import { isLoggedIn } from "../services/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== `/login`) {
    window.location.replace("/login")
    return null
  }
  
  return <Component {...rest} />
}

export default PrivateRoute