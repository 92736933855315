import React from 'react'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'

export const ExportCSV = ({ csvData, fileName }) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <>
            <button
                // style={{ backgroundColor: '#21b8ba'}}
                className="text-white font-small text-xs py-1 px-2 rounded float-right bg-cyan-500"
                onClick={(e) => exportToCSV(csvData, fileName)}
            >
                <FontAwesomeIcon icon={faCloudDownloadAlt} /> Export
            </button>
        </>
    )
}