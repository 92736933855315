import React from "react"
import NavBar from "./nav-bar"
import { isLoggedIn } from "../services/auth"
import Login from "./login"
import { generateTitle } from "../services/utils"
import Signup from './signup'
import FourOFour from './404'
import { useLocation } from "react-router"
import ForgotPassword from "./forgot-password"

export default function Layout({ children }) {
  const location = useLocation();

  const title = generateTitle(location.pathname);

  const uri = window.location.pathname.split("/")

  const loggedIn = isLoggedIn();

  const site = JSON.parse(window.localStorage.getItem("plutoSite"))

  document.title = title + ' | Volunteering Organisation Portal';

  if (loggedIn && uri[1] === '') {
    window.location.replace('/dashboard')
  } else if (!loggedIn && uri[1] === '') {
    window.location.replace('/login')
  }

  return (
    <>
      {loggedIn ? 
          <div className="min-h-full">
            <NavBar />
            <header className="bg-white shadow">
              <div className="flex max-w-[90rem] mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <img
                  className="h-10"
                  src={site.icon_url ? site.icon_url : 'https://sassets.sumsmanagement.com/1.0/v4/img/sums_v4_logo_3.png'}
                  alt={site.name + ' logo'}
                />
                <h1 className="ml-5 text-3xl font-bold text-gray-800">{title}</h1>
              </div>
            </header>
            <main className="h-screen">
              <div className="max-w-[90rem] mx-auto py-6 xs:px-6 sm:px-6 lg:px-8">
                {children}
              </div>
            </main>
          </div>
      : uri[1] === 'signup' ? <Signup siteId={uri[2]} /> : uri[1] === 'login' ? <Login siteId={uri[2]} /> : uri[1] === 'forgot-password' ? <ForgotPassword siteId={uri[2]} /> : <FourOFour />}
    </>
  )
}