import { plutoClient } from "../services/http"
import { PlutoAPIRoutes } from "../constants/pluto"

export const getOrganisation = async (values) => {
    return await plutoClient(
        PlutoAPIRoutes.EMPLOYABILITY_ORGANISATION,
        'GET',
        {},
        {authHeader: true, siteId: true, headers: {}},
        false,
    ).then(
        async (response) => { 
            return response
        },
        async (errors) => {
            return {
                success: false,
                content: errors
            }
        },
    );
}

