import React, { useState, useEffect } from "react"
import { resetPassword, isLoggedIn } from "../services/auth"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Alert, Loading } from "./utils";
import { plutoClient } from "../services/http"
import { PlutoAPIRoutes } from "../constants/pluto"
 
const ForgotPassword = (props) => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const [site, setSite] = useState('');

   if (isLoggedIn()) {
    window.location.replace('/dashboard')
  }

  useEffect(() => {
    plutoClient(
      PlutoAPIRoutes.SITES + '/' + props.siteId,
      'GET',
      {},
      {authHeader: false, headers: {'X-Site-Id': props.siteId}},
    ).then(
      async (response) => {
        setSite(response);
        setLoading(false);
      },
      async (error) => {
        setError('Invalid Site ID Parameter');
        setLoading(false);
      },
    );
  }, [props]);

  return (
    <div style={{backgroundColor: site.primary_colour ?? '#fff'}} className="flex items-center justify-center min-h-screen bg-white">
      <div className="px-8 py-6 mt-4 text-left bg-white md:w-1/2 lg:w-1/4">
        {loading ? <Loading /> :
          <>
          {site.logo_url &&
            <div>
              <img
                className="mx-auto h-24 w-auto mb-8"
                src={site.logo_url}
                alt={site.name + ' logo'}
              />
            </div>
          }
          <h3 className="my-5 text-xl text-center leading-6 font-medium text-gray-700">Reset Password</h3>
          {error ? <Alert title="Oops!" message={error} /> : ''}
          {success !== '' ? <Alert title="Success!" message={success} success /> : ''}
            <Formik
              className="mt-8 space-y-6"
              initialValues={{ email: '' }}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                  const response = await resetPassword(values, site);
                  
                  setSubmitting(false)

                  if (response.success) {
                    setSuccess('You will recieve an email to reset your password in the next 15 minutes')
                  } else {
                    setError('An unexpected error occured. If this keeps happening, please contact ' + site.name)
                    setErrors(response.content.errors)
                  }
              }}
            >
              {({ isSubmitting }) => (
                success === '' ? 
                  <Form>
                  <div className="rounded-md -space-y-px">
                    <div>
                      <label htmlFor="email-address" className="sr-only">
                        Email address
                      </label>
                      <Field type="email" name="email" placeholder="Email address" className="form-control
                        block
                        w-full
                        px-3
                        py-1.5
                        text-base
                        font-normal
                        text-gray-700
                        bg-white bg-clip-padding
                        border border-solid border-gray-300
                        rounded
                        transition
                        ease-in-out
                        m-0
                        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" />
                      <ErrorMessage name="email" component="div" className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-2"/>
                    </div>
                    <div>
                      <button style={{backgroundColor: site.primary_colour ?? '#999'}} type="submit" disabled={isSubmitting} className="mt-4 mb-2 w-full inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-normal uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                        Submit
                      </button>
                    </div>
                  </div>
                </Form> : <></>
              )}
            </Formik>
          </>
        }
        </div>
    </div>
  );
};

export default ForgotPassword