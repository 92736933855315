import { plutoClient } from "../services/http"
import { PlutoAPIRoutes } from "../constants/pluto"
import { getSite } from "../services/utils";

const site = getSite();

export const getSettings = async (ids) => {
    return await plutoClient(
        PlutoAPIRoutes.SETTINGS + '?settingsIds=' + ids,
        'GET',
        {},
        {authHeader: true, headers: {'X-Site-Id': site.id}},
    ).then(
        async (response) => {
            return response
        },
        async (errors) => {
            return {
                success: false,
                content: errors
            }
        },
    );
}