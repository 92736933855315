import { plutoClient } from "../services/http"
import { PlutoAPIRoutes } from "../constants/pluto"
import { getSite } from "../services/utils";

const site = getSite();

export const updatePlacement = async (values, id) => {
    return await plutoClient(
        PlutoAPIRoutes.EMPLOYABILITY_PLACEMENTS + '/' + id,
        'PUT',
        values,
        {authHeader: true, headers: {'X-Site-Id': site.id}},
    ).then(
        async (response) => { 
            return {
                success: true,
            }
        },
        async (errors) => {
            return {
                success: false,
                content: errors
            }
        },
    );
}
